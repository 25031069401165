@import 'src/style/variables/index.scss';
ul {
    list-style: none;
    padding : 0;
    max-width: 60ch;
}

ul, li, h5 {
    display: inline;
}

h4 {
    margin-bottom: .5rem;
}

h5 {
    font-weight: 600;
    font-size: 1rem;
    margin: 0;
}

div {
    margin-bottom: .25rem;
}