@import 'src/style/variables/index.scss';
.hero {
    display: flex;
}

.hero__bottom {
    margin-top: 8rem;
}

.hero__heading {
    font-size: 3rem;
    margin-bottom: 0;
}

.hero__subheading {
    margin-top: 0;
}